import { SAVE_NORMA, NORMA_ERRORS, DELETE_NORMA } from '../types'
import { SAVE_NORMA_FILE, NORMA_FILE_ERRORS, GET_NORMA_FILE } from '../types'
import { SET_SELECTED_NORMA, SET_NORMA_ASOC_LIST_AJU } from '../types'
import { NORMA_ASOCIADA_ERRORS, GET_NORMA_ASOCIADA_LIST } from '../types'
import { SAVE_NORMA_ASOCIADA, DELETE_NORMA_ASOCIADA } from '../types'
import { GET_NORMA_EXCEL, NORMA_EXCEL_ERRORS } from '../types'
import axios from 'axios'

export const saveNormaFile = (datos) => {
    let formData = new FormData();
    formData.append('fileUpload', datos.fileUpload)

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        }
    }

    return async (dispatch, getState) => {
        try {
            const res = await axios.post(
                process.env.REACT_APP_API_URL + 'files', formData, config);
            dispatch({ type: SAVE_NORMA_FILE, payload: res });
        }
        catch (e) {
            dispatch({
                type: NORMA_FILE_ERRORS,
                payload: e
            });
        }
        return {
            "fileStatus": getState().norma.fileStatus,
            "fileMessage": getState().norma.fileMessage
        };
    }
};

export const saveNorma = (entidad, res) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return async (dispatch, getState) => {
        try {
            if (entidad?.id) {
                const res = await axios.put(process.env.REACT_APP_API_URL + 'norma/' +
                    entidad.id, entidad, config);
                dispatch({ type: SAVE_NORMA, payload: res });
            } else {
                const res = await axios.post(process.env.REACT_APP_API_URL + 'norma/',
                    entidad, config);
                dispatch({ type: SAVE_NORMA, payload: res });
            }

        }
        catch (e) {
            dispatch({ type: NORMA_ERRORS, payload: e });
        }
        return getState().norma.normaStatus;
    }
};

export const setSelectedNorma = (entidad) => {
    return (dispatch, getState) => {
        dispatch({ type: SET_SELECTED_NORMA, payload: entidad })
    }
}

export const getNormaAsociada = (entidad) => {
    return async (dispatch, getState) => {
        let listaAjustada = [];
        try {
            const res = await axios.get(process.env.REACT_APP_API_URL +
                'norma-asocia/norma-asociada?normaId=' + entidad.id);
            dispatch({ type: GET_NORMA_ASOCIADA_LIST, payload: res.data });

            let listaAux = []

            for (const [i, elemento] of res.data.entries()) {
                listaAux.push(elemento);
                if (entidad.id === elemento.normaAsociadaId.id) {
                    listaAux[i].normaAsociadaId = elemento.normaId;
                    listaAux[i].normaId = entidad;
                }
            };

            listaAjustada = listaAux;
            listaAjustada.sort((a, b) => {
                const dateA = new Date(a.normaAsociadaId.fechaElaboracion);
                const dateB = new Date(b.normaAsociadaId.fechaElaboracion);

                if (dateB - dateA !== 0) {
                    return dateB - dateA;
                }

                return b.normaAsociadaId.numerdo.localCompare(a.normaAsociadaId.numero);
            })
            dispatch({ type: SET_NORMA_ASOC_LIST_AJU, payload: listaAjustada });
        } catch (error) {
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                dispatch({ type: NORMA_ASOCIADA_ERRORS, payload: error.response });
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error);
        }
        return listaAjustada;
    }
}

export const saveNormaAsociada = (entidad) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return async (dispatch, getState) => {
        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + 'norma-asocia',
                entidad, config);
            dispatch({ type: SAVE_NORMA_ASOCIADA, payload: res });
        } catch (error) {
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                dispatch({ type: NORMA_ASOCIADA_ERRORS, payload: error.response });
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error);
        }
        return getState().norma;
    }
};

export const deleteNormaAsociada = (entidad) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return async (dispatch, getState) => {
        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL +
                'norma-asocia/' + entidad, config);
            dispatch({ type: DELETE_NORMA_ASOCIADA, payload: res });
        } catch (error) {
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                dispatch({ type: NORMA_ASOCIADA_ERRORS, payload: error.response });
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error);
        }
        return getState().asociacionNorma;
    }
};


export const getNormaFile = (nombreArchivo) => {
    return async (dispatch, getState) => {
        try {
            // eslint-disable-next-line no-unused-vars
            const res = await axios({
                url: process.env.REACT_APP_API_URL + 'files/' + nombreArchivo,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                dispatch({ type: GET_NORMA_FILE, payload: response });
                return response.data;
            }).catch((e) => {
                dispatch({ type: NORMA_FILE_ERRORS, payload: e });
                return e.message;
            })
        }
        catch (e) {
            dispatch({ type: NORMA_FILE_ERRORS, payload: e });
        }

        return {
            "fileStatus": getState().norma.fileStatus,
            "fileMessage": getState().norma.fileMessage,
            "fileContent": getState().norma.fileContent
        };
    }
};

export const deleteNorma = (entidad, msjBorrado) => {
    return async (dispatch, getState) => {
        try {
            if (entidad?.id) {
                const res = await axios.post(process.env.REACT_APP_API_URL + 'norma/borrar',
                    {}, {
                    params: { id: entidad.id, textoBorrar: msjBorrado },
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                dispatch({ type: DELETE_NORMA, payload: res });
            }
        }
        catch (error) {
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                dispatch({ type: NORMA_ERRORS, payload: error });
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error);
        }
        return getState().norma.normaStatus;
    }
};

export const getExcelNormas = () => {
    return async (dispatch, getState) => {
        try {
            // eslint-disable-next-line no-unused-vars
            const res = await axios({
                url: process.env.REACT_APP_API_URL + 'norma/norma-excel/',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                dispatch({ type: GET_NORMA_EXCEL, payload: response });
                return response.data;
            }).catch((e) => {
                console.log(e);
                dispatch({ type: NORMA_EXCEL_ERRORS, payload: e });
                return e.message;
            })
        }
        catch (e) {
            dispatch({ type: NORMA_EXCEL_ERRORS, payload: e });
        }

        return {
            "fileMessage": '',
            "fileContent": getState().norma.fileContent
        };
    }
};

